.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
    background: #fafafa;
    padding: 0 0 12px 0;
    box-shadow: 0 1px 8px -1px #0000005e;
	// box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	// transition: all 0.4s ease;

}


.header-desktop {
	max-height: 100px;
	text-align: center;

	@include respond-to('medium') {
		display: none;
	}

	&__buttons {
		text-align: initial;
		padding-top: 22px;
		height: 55px;
	}
}

.header-desktop__logo {
	max-width: 215px;
	max-height: 55px;
	float: left;
}

.logo {
	padding-bottom: 60px;
}
.header-right{
width: 50%;
float: right;
padding: 0 0 0 112px;
}
.header-middle{
	position: absolute;
	right: 0;
	left: 0;
	margin: 0 auto;
    width: 230px;
}
.header-left{
	width: 50%;
	float: left;
    padding: 0 112px 0 0;
}
.header-middle{
	text-align: center;
	.logo{
		padding: 12px 5px 0;
	}
}
.main-navi {
    width: 100%;
    float: right;
    text-align: justify;
	&>a{
		display: inline-block;
	}
	& .right-menu {
		display: inline-block;
		list-style: none;
		margin: side-values(45px 0 0 0);
		padding: 0;
		display: inline-block;

		&>ul {
			display: inline-block;
			list-style: none;
			padding: 0;
			margin: 0;

			&>li {
				float: right;
				margin-#{$left}: 35px;
				position: relative;
				padding-bottom: 11px;
				@include respond-to('large') {
					margin-#{$left}: 20px;
				}

				&>a {
					color: #000000;
					font-size: 17px;
					text-align: justify;
					font-weight: 500;

					&:hover {
						// border: 1px solid #000;
						// border-radius: 3px;
						border-bottom: 2px solid green;
						text-decoration: none;
					}

					&:focus,
					&:active {
						text-decoration: none;

					}
				}

				&:hover {
					&>ul {
						opacity: 1;
						visibility: visible;
					}
				}

				/* level 2*/
				&>ul {
					visibility: hidden;
					opacity: 0;
					transition: all 0.4s;
					position: absolute;
					top: 50px;
					left: 50%;
					transform: translateX(-50%);
					padding: 15px;
					list-style: none;
					background-color: #04922D;
					min-width: 169px;
					padding: 0;

					&:before {
						// arrow
						content: '';
						position: absolute;
						top: -7px;
						left: 50%;
						transform: translateX(-50%) rotate(45deg);
						width: 20px;
						height: 20px;
						background-color: #04922D;
						z-index: 8;
					}

					&>li {
						color: #fff;
						&>a {
							position: relative;
							color: #fff;
							display: block;
							font-size: 14px;
							padding: 10px 5px;
							border-top: 1px solid #fff;
							color: #fff;
							z-index: 9;
							text-align: center;

							&:hover {
								text-decoration: none;
								color: #fff;
								background: #ffffff38;
							}

						}
						&:first-child>a{
							border-top: none;
						}
					}
				}

				/* END level 2*/
			}
		}
	}

	& .left-menu {
		list-style: none;
		padding: 0;
		margin: side-values(45px 0 0 0);
		display: inline-block;
		float: left;

		&>ul {
			display: inline-block;
			list-style: none;
			padding: 0;
			margin-bottom: 0;

			&>li {
				float: right;
				margin-#{$right}: 35px;
				position: relative;
				@include respond-to('large') {
					margin-#{$right}: 20px;
				}

				&>a {
					color: #000000;
					font-size: 17px;
					text-align: justify;
					font-weight: 500;

					&:hover {
						// border: 1px solid #000;
						// border-radius: 3px;
						border-bottom: 2px solid green;
						text-decoration: none;
					}

					&:focus,
					&:active {
						text-decoration: none;
					}
				}

				&:hover {
					&>ul {
						opacity: 1;
						visibility: visible;
					}
				}

				/* level 2*/
				&>ul {
					visibility: hidden;
					opacity: 0;
					transition: all 0.4s;
					position: absolute;
					top: 50px;
					left: 50%;
					transform: translateX(-50%);
					padding: 15px;
					list-style: none;
					background-color: #04922D;
					min-width: 210px;

					&:before {
						// arrow
						content: '';
						position: absolute;
						top: -7px;
						left: 50%;
						transform: translateX(-50%) rotate(45deg);
						width: 20px;
						height: 20px;
						background-color: #04922D;
					}

					&>li {

						&>a {
							color: #fff;
							display: block;
							font-size: 18px;
							padding: 3px;

							&:hover {
								text-decoration: none;
								color: #fff;
							}

						}
					}
				}

				/* END level 2*/
			}
		}
	}
}


.icon-house {
	vertical-align: top;
	border-bottom: 2px solid transparent;
	margin-#{$left}: 40px;

	&:hover {
		border-bottom: 2px solid green;
		text-decoration: none;
	}
}



// .wrap-lang {
// 	position: relative;
// 	display: inline-block;
// 	vertical-align: top;
// 	margin-top: 35px;
// 	// &::before {
// 	// 	font-family: "Font Awesome 5 Pro";
// 	// 	font-weight: 300;
// 	// 	content: "\f107";
// 	// }
// 	& > i {
// 	  position: absolute;
// 	  top: 26%;
// 	  #{$right}: 10px;
// 	  pointer-events: none;
// 	  color: #000;
// 	}
// }

// .select-lang {
// 	padding: 5px 33px 6px 10px;
// 	font-size: 16px;
// 	color: #000;
// 	background-color: transparent;
// 	border: 1px solid #000;
// 	cursor: pointer;
// 	border-radius: 3px;
// 	background-image: none;
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	&:focus {
// 		outline: none;
// 	}
// 	& > option {
// 		color: #000;
// 	}
// }

.header-mobile {
	display: none;
	height: 70px;

	@include respond-to('medium') {
		display: block;
	}

	&__logo {
		// margin-#{$left}: 15px;
		float: $left;

		@include respond-to('extra-small') {
			width: 150px;
			height: 50px;
			margin-top: 10px;
		}

		&>img {
			max-width: 100px;

		}
	}

	&__tel {
		display: inline-block;
		margin-top: 17px;

		i {
			color: #000;
			font-size: 32px;
		}
	}
}


// Change size head menu when scrolling//


// .main-header.scrolling {
// 	.header-desktop {
// 		height: 80px;
// 	}
// 	.main-navi {
// 		& > ul {
// 			margin-top: 25px;
// 		}
// 	}
// 	.wrap-lang {
// 		margin-top: 25px;
// 	}
// 	.logo {
// 		top: 15px;
// 	}
// }


.logo-link:hover{
	opacity: 0.7;
}