#filterForm{
  @include respond-to('medium'){
    display: none;
  }
}

.wrap-catalog-items-filtering{

  &__mobile-btn-open-filtering{
    display: none;
    background-color: $clr-purple;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.5);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
    & i{
      margin-#{$right}: 15px;
    }
    @include respond-to('medium'){
      display: block;
    }
  }

  .list-group{
    padding: side-values(0 10px 15px);
  }

  .list-group-item{
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-bottom: 10px;

  }

  .list-group-label{
    display: block;
    position: relative;
    padding-#{$right}: 35px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    color: #777777;
    margin: 0 auto;
    line-height: 1.2;
    &:hover{
      color: #000;
    }
  }

  .list-group-label__input{
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .list-group-label__indicator{
    position: absolute;
    top: 0;
    #{$right}: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #d6d6d6;
    background-color: #fff;
    &:after{
      content: '';
      position: absolute;
      display: none;
      #{$left}: 7px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

  }

  .list-group-label:checked{
    color: $clr-purple;
  }

  .list-group-item input:checked  ~ .list-group-label__indicator:after,
  .list-group-item input:disabled ~ .list-group-label__indicator:after {
    display: block;
  }

  .list-group-item input:hover  ~ .list-group-label__indicator:after {
    display: block;
  }

  .list-group-item input:not([disabled]):checked:focus ~ .list-group-label__indicator,
  .list-group-item input:not([disabled]):checked ~ .list-group-label__indicator,
  .list-group-item input:not([disabled]):checked ~ .list-group-label__indicator, {
    background: $clr-purple;
    border-color: transparent;

  }

  .list-group-item input:disabled:checked ~ .list-group-label__indicator {
    background: #000;
    border:1px solid #000;
  }

  .list-group-item:hover input ~ .list-group-label__indicator{
    background: $clr-purple;
    opacity: 0.5;
  }

  .list-group-item:hover input:disabled ~ .list-group-label__indicator{
    background: #000;
    opacity: 1;
  }

  //################ filter colors

  .list-group-horizontal{

    div[class^="col-"]{
      padding: 0 10px;
    }

    div[class="row"]{
      margin: 0 -10px;
    }

    &:hover{
      input ~ .list-group-label__indicator{
        opacity: 1;
      }
    }
    .list-group-item{
      display: block;
      margin: 0 auto 5px;
      text-align: center;
    }
    .list-group-label{
      padding: 0;
      width: 26px;
      height: 26px;
      outline:1px solid #d6d6d6;
      &__indicator{
        border: 0;
        width: 26px;
        height: 26px;
        &:after{
          width: 18px;
          height: 18px;
          border: 3px solid #fff;
          transform: initial;
          top: 4px;
          #{$left}: 4px;
        }
        &:before{
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border: 1px solid #d6d6d6;
          transform: initial;
          top: 3px;
          #{$left}: 3px;
          display: none;
        }
      }
    }
  }

  .filter-only-colors{
    &:hover{
      & > label{
        &:after{
          content: '';
          position: absolute;
          width: 18px;
          height: 18px;
          border: 3px solid #fff;
          transform: initial;
          top: 4px;
          #{$left}: 4px;
          pointer-events: none;
        }
        &:before{
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border: 1px solid #d6d6d6;
          transform: initial;
          top: 3px;
          #{$left}: 3px;
          z-index: 1;
          pointer-events: none;
        }
      }
    }
  }

  .list-group-label__indicator-color-active{
    &:after{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border: 3px solid #fff;
      transform: initial;
      top: 4px;
      #{$left}: 4px;
    }
    &:before{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #d6d6d6;
      transform: initial;
      top: 3px;
      #{$left}: 3px;
      z-index: 1;
    }
  }

  .list-group-label-name-color{
    line-height: 1;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .list-group-item input:checked  ~ .list-group-label__indicator:before,
  .list-group-item input:disabled ~ .list-group-label__indicator:before {
    display: block;
  }

  .list-group-item input:hover  ~ .list-group-label__indicator:before{
    display: block;
  }

  // ####################### filter range price


.slider.slider-horizontal{
	width:98%;
  max-width: 280px;
}

  .slider.slider-horizontal .slider-track{
    height: 8px;
  }

  .slider-track{
    height: 8px;
    background-color: #d6d6d6;
    box-shadow: none;
    background-image: none;
    border-radius: 0;
  }

  .slider-selection{
    background-color: $clr-purple;
    background-image: none;
    box-shadow: none;
  }

  .slider.slider-horizontal .slider-tick,
  .slider.slider-horizontal .slider-handle {
      margin-left: -10px;
      margin-top: 5px;
      &:hover{
        cursor: pointer;
      }
  }

  #filterForm{
    .tooltip-inner{
      background-color: transparent;
      color: #777;
    }
    .tooltip-arrow{
      display: none;
    }
    .tooltip.bottom,
    .tooltip.top{
      padding: 0;
      margin-right: -22px;
      min-width: 42px;
    }
    .tooltip-max{
      #{$right}: 0;
    }
  }
  .tooltip-inner{
    white-space: nowrap;
  }

  .slider-handle {
    position: absolute;
    width: 20px;
    height: 8px;
    background-image:none;
    background-color: #ffffff;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.3);
  }

  .slider-handle:after{
    content: " ";
    width: 10px;
    height: 4px;
    float: $right;
    margin-top: 2px;
    margin-#{$right}: 5px;
    background-color: #777777;
  }

  .value {
    position: absolute;
    top: 19px;
    left: 50%;
    margin: 0 0 0 -20px;
    width: 40px;
    text-align: center;
    display: block;
    /* optional */
    font-size: 14px;
    color: #000;
  }

  .price-range-both.value {
    width: 100px;
    margin: 0 0 0 -50px;
    top: 26px;
  }

  .price-range-both {
    display: none;
  }

  .value i {
    font-style: normal;
  }

  .btn-reset,.btn-reset-selection{
    float:left; 
    margin-top:-32px;
    margin-bottom:12px;
    background-color:#584d71;
    color:#fff;
  }
}
