.main-gallery {
	padding-top: 20px;
	padding-bottom: 20px;
    max-width: 1213px;
    margin: 0 auto;
}

.slider-gallery {
	padding-top: 100px;
	position: relative;
	&>img{
		width: 100%;
	}
}

.about-gallery {
	position: relative;
	text-align: justify;
	margin-bottom: 35px;
    padding: 0 15px;

	@include respond-to('medium') {
		padding: 10px;
	}

	& h1 {
		color: #04922d;
		font-weight: 700;
		font-size: 38px;
	}

	& p {
		font-size: 16px;
		font-weight: normal;
	}
}

.gallery-item {
	width: 25%;
	float: $right;
	position: relative;
	display: block;
	overflow: hidden;
	// height: 350px;
	text-align: center;

	@include respond-to('small') {
		width: 50%;
	}

	@include respond-to('extra-small') {
		width: 100%;
		max-width: 400px;
		float: none;
		margin: 0 auto;
	}

	&_long {
		width: 50%;

		& .gallery-item__wrapper {
			padding-bottom: 60%;

			@include respond-to('small') {
				padding-bottom: 70%;
			}

			@include respond-to('extra-small') {
				padding-bottom: 60%;
			}
		}

		@include respond-to('extra-small') {
			width: 100%;
		}
	}

	&__wrapper {
		overflow: hidden;
		position: relative;
		width: 100%;
		padding-bottom: 120%; // control box proportions 

		@include respond-to('small') {
			padding-bottom: 70%;
		}

		@include respond-to('extra-small') {
			padding-bottom: 60%;
		}
	}

	&__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 5px;
	}

	&:hover {
		.gallery-item__img {
			// transform: scale3d(1.1, 1.1, 1.1);
		}

		.gallery-item__overlay {
			opacity: 1;
		}
	}

	&__img {
		background-color: #ccc;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: block;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffff4d;
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		& i {
			font-size: 40px;
			color: #fff;
		}
	}

	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;

		@include respond-to('1100') {
			bottom: 10px;
			width: 90%;
		}

		@include respond-to('medium') {
			font-size: 16px;
			font-weight: 400;
		}
	}
}


.cat-box-proj {
	position: relative;
	display: block;
	max-width: 350px;
	width: 100%;
	height: 450px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	&:hover {
		text-decoration: none;
	}

	&__title {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		padding: 10px;
		text-align: center;
		background: rgba(255, 211, 0, 0.8);

		&_style {
			font-size: 22px;
			font-weight: 600;
			color: #000;
		}
	}
}
.breadcrumbs-gallery-wrap{
    padding: 0 15px;
}