.wrap-three-cat-box {
	&:nth-child(even) {
		.col-md-6 {
			float: $left;
		}
	}
}



.wrap-category--loader {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: hsla(0,0%,100%,.9);
  z-index: 100
}


.wrap-category--loader .loader {
  position: absolute;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  display: block;
  text-align: center;
  margin: 0 auto;
  top: 400px
}