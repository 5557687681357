footer {
    max-width: 100%;
    background: #fafafa;
    z-index: 1;
    border-top: 2px solid green;
    text-decoration: none;

    // padding: 50px 0 20px;
    @include respond-to('large') {
        padding: 20px 0;
    }
}
.ftr-wrap{
    padding: 14px 0 0 0;
}
.hr-footer-bottom {
    border: 0;
    margin-bottom: 15px;
}

.credit-info-txt {
    display: inline-block;
    margin: 0 0 10px 0;
    @include respond-to('medium') {
        display: block;
        text-align: center;
    }

    @include respond-to('small') {
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        color: #000;
        font-size: 16px;
        font-weight: 100;
        text-align: center;
        letter-spacing: -3px;
        li {
            letter-spacing: 0;
            padding: 0 7px;
            position: relative;
            display: inline-block;
            &:after {
                content: '|';
                position: absolute;
                height: 100%;
                width: 1px;
                right: 0;
            }
            &:first-child:after{
                content: none;
            }
            a{
                color: #000;
            }
        }
    }
}

.inline-data {
    // display: flex;
    //   justify-content: space-between;
    width: 1200;
    padding: 0 10px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

}