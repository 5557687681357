
.recommended-package__title{
  font-size: 26px;
  font-weight: 800;
  color: #000;
  margin-bottom: 20px;
  & > span{
    color: #981b1e;
  }
}

.product-add-title{
  font-size: 26px;
  color: #000;
  margin: 20px 0 8px;
  font-weight: 600;
}

.wrap-add-products{
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  & > .slide-product-boxes{
    margin: -50px 0 -60px;
  }
}

.product-item__sale-slide{
  margin-bottom: 30px;
}

.recommended-package-ul{
  display: inline-block;
  margin-top: 50px;
  .list-group-item{
    background-color: initial;
  }
}

.recommended-package-ul__club{
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: #a2bb3b;
  & > span{
    font-family: 'Lobster';
    font-weight: normal;
  }
}

.wrap-recommended-package-list-prod{
}

.recommended-package-list-prod{
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

.recommended-package-list-prod__item{
  width: 16%;
  height: 150px;
  position: relative;
  float: right;
  @include respond-to('small'){
    width: 33%;
  }
  &:after{
    content: attr(data-content-css);
    color: #777777;
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: -12px;
    @include transform(translateY(-50%));
  }
  &:last-child{
    &:after{
      content: "";
    }
  }
  & > img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
  }
}

.recommended-package-title-and-btn{
  display: inline-block;
  margin-right: 30px;
  height: 150px;
  padding-top: 10px;
  position: relative;
  &:after{
    content: "=";
    color: #777777;
    font-size: 26px;
    position: absolute;
    top: 50%;
    right: -30px;
    @include transform(translateY(-50%));
  }
}

.recommended-package-btn-title{
  font-size: 18px;
  color: #777777;
  text-align: center;
  line-height: 1;
  margin-bottom: 5px;
  & > span{
    font-size: 30px;
    font-weight: 800;
    color: #000;
  }
}

.recommended-package-btn{
  border: 0;
  background-color: #981b1e;
  font-size: 28px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  width: 230px;
  height: 60px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
  &:active{
    @include btn-effect-active;
  }
}

.list-group-item{
  border: 0;
  background-color: #f6f6f6;
  padding: 0;
}

.list-group-label{
  display: block;
  position: relative;
  padding-right: 27px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #777777;
  margin-bottom: 8px;
  font-weight: normal;
}

.list-group-label__input{
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.list-group-label__indicator{
  position: absolute;
  top: 2px;
  right: 0;
  height: 17px;
  width: 17px;
  border: 1px solid #981b1e;
  border-radius: 3px;
  background-color: #fff;
  &:after{
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.list-group-label:checked{
  .color-red{
    color: #981b1e;
  }
}

.list-group-item input:checked  ~ .list-group-label__indicator:after,.list-group-item input:disabled ~ .list-group-label__indicator:after {
  display: block;
}

.list-group-item input:not([disabled]):checked:focus ~ .list-group-label__indicator,
.list-group-item input:not([disabled]):checked ~ .list-group-label__indicator,
.list-group-item input:not([disabled]):checked ~ .list-group-label__indicator, {
  background: #981b1e;
}

.list-group-item input:disabled:checked ~ .list-group-label__indicator, {
  background: #000;
  border:1px solid #000;
}

.list-group-item:hover input ~ .list-group-label__indicator{
  background: #981b1e;
  opacity: 0.5;
}

.list-group-item:hover input:disabled ~ .list-group-label__indicator{
  background: #000;
  opacity: 1;
}