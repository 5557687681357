.wrapper-all-content {
    width: 100%;
    text-decoration: none;

    & .col-md-12 {
        padding: 0;
    }

}

// h2:after, h2:before {
//     content: "";

// }
