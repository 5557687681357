.question {
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.1);
	&__item {
		// border: 1px solid #e5e5e5;
		border-bottom: none;
		&:nth-child(even) {
			background-color: #fbfbfb;
			box-shadow: inset 0px 15px 10px -10px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.05);
		}
	}
	&__btn {
		& a {
			position: relative;
			display: block;
			min-height: 70px;
			padding: side-values(25px 30px 20px 105px);
			@include link_no-hover($text);
			font-size: 20px;
			font-weight: 400;
			line-height: 1;
			// @include respond-to('1100') {
			// 	padding: 20px 0;
			// }
			@include respond-to('small') {
				padding: side-values(25px 20px 20px 75px);
			}
			// @include respond-to('extra-small') {
			// 	padding: 12px 0;
			// }
			& span.arrow {
				position: absolute;
				left: 0;
				top: 0;
				max-width: 100px;
				width: 100%;
				height: 100%;
				border-#{$right}: 1px solid #e5e5e5;
				@include respond-to('small') {
					max-width: 70px;
				}
			}
			&[aria-expanded=true] {
				.arr-in {
					&::before {
                        transform: translate(-50%, -50%) rotate(0deg);
                        filter: brightness(0) invert(1);
					}
					&::after {
						background-color: #ee2e2f;
					}
				}
			}
		}
	}
	&__body {
		box-shadow: inset 0px 15px 10px -10px rgba(0, 0, 0, 0.05);
		& .content-text {
			padding: 50px;
			@include respond-to('small') {
				padding: 20px;
				font-size: 16px;
				text-align: justify;
			}
			& * {
				color: $text;
			}
		}
	}
}

.arr-in {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	&::after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		height: 40px;
		background-color: #f4f4f4;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
	}
	&::before {
		content: url('../images/arr-bottom.png');
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -42%) rotate(180deg);
		transition: transform 0.2s ease, rotate 0.2s ease;
	}
}