.wrapper-all-content {
	background-image: url(/assets/images/bg.jpg);
	position: relative;
	width: 100%;
	padding: 0;
}
body{
	background: #fafafa;
}
.homepage__buttons {
	padding: 20px 0;
}

.center-text {
	padding: 69px 0 61px;
	color: #141414;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin: 0 auto;
	
	@include respond-to('small') {
	padding-top: 20px;
	padding-bottom: 40px;
	}
}
.title-homepage {
	&>p {
		margin: 0;
		color: #04922D;
		font-size: 30px;
		font-weight: 700;
		text-align: center;
		line-height: 20px;
	}

	&__icon {
		padding-top: 25px;
	}
}

.center-blocks {
	// flex-direction: row;
	// justify-content: space-between;
	padding-top: 50px;
	display: flex;
	flex-wrap: nowrap;
    flex-flow: row wrap;
    justify-content: space-around;
	@include respond-to('medium') {
		display: flex;
	flex-wrap: wrap;
		}
}


.center-bl {
	text-align: center;
	line-height: 60px;
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 35px;
	@include respond-to('medium') {
		margin-left: auto;
		margin-right: auto;
		padding: 0 5px;
		font-size: 15px;
		img{
			transform: scale(0.7);
		}
	}
	img{
	}
}

.center-txt {
	color: #141414;
	font-size: 16px;
	line-height: 20px;
	padding-top: 25px;

	// @include respond-to('extra-small') {
	// 	line-height: 20px;
	// }
	// @include respond-to('small') {
	// 	line-height: 20px;
	// }
}

.small-icon {
	padding-top: 10px;
}

.bottom-menu {

	&>p {
		margin: 0;
		padding-top: 35px;
	}

	text-align: right;

	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;
	& .col-md-12 {
		padding: 0;
	}

	@include respond-to('medium') {
		background-image: none;
		
	}
	@include respond-to('larger'){
		padding:10px;
	}

	
}

.btn {
	border: 3px solid #04932E;
	border-radius: 0;
	width: 95px;
	height: 47px;
	background-color: white;
	text-decoration: none;
	color: #04932E;
	font-size: 19px;
	margin-top: 0;
	margin-bottom: 69px;
	text-align: center;
	font-weight: 400;
}

.name,
.phone,
.tag,
.message {
	font-weight: 500;
	padding: 12px 0;
	margin: 20px 0;
	border: none;
	width: 100%;
	max-width: 465px;
	height: 1px;
	border-bottom: 2px solid #C4C4C4;
	background-color: transparent;

	&::placeholder {
		padding: 0;
		color: #141414;
		font-weight: 500;
	}

	&>.phone,
	.tag,
	.message {
		padding-top: 30px;
	}
	@include respond-to('medium'){
		width: 100%;
	}
	@include respond-to('extra-small'){
		max-width: 250px;
	}
}

.btmtitle {
    color: #04922d;
	font-size: 20px;
    padding: 8px 0;
	font-weight: 700;
	
	@include respond-to('extra-small'){
		font-size: 20px;
	}
}

.global-menu {

	& .col-md-6 {
		padding: 4px;
	}
}

.homepage-box {
	position: relative;
	display: block;

	&__image {
		overflow: hidden;
		img {
			transition: transform 200ms;
		}
		&:hover{
			img{
				opacity: 0.7;
			}
		}
	}


	&__title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		h2 {
			position: relative;
			font-size: 30px;
			padding-bottom: 15px;
			padding-left: 25px;
			margin: 0;
			z-index: 10;
			color: #FFFFFF;
			font-size: 20px;
			font-weight: 700;
			text-align: left;
		}

		&::before {

			content: " ";
			position: absolute;
			height: 100%;
			left: 0;
			right: 0;
			z-index: 0;
		}
	}

	&__title-lt {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		h2 {
			position: relative;
			font-size: 30px;
			padding-bottom: 15px;
			padding-right: 25px;
			margin: 0;
			z-index: 10;
			color: #FFFFFF;
			font-size: 20px;
			font-weight: 700;
			text-align: right;
		}

		&::before {

			content: " ";
			position: absolute;
			height: 100%;
			left: 0;
			right: 0;
			z-index: 0;
		}
	}
}
#sb-site{
	background: none;
}
.light-font{
	font-weight: normal;
}
.homepage-rects{
	padding-bottom: 4px;
	& > div{
		&:nth-child(odd){
			float: right;
			clear: both;
		}
		&:nth-child(even){
			float: left;
		}
	}
}