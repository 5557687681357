.wrap-content {
	padding: 0 15px;
	@include respond-to('medium') {
		padding-left: 10px;
		padding-right: 10px;
	}
}
.content-wrap{
	padding-top: 0;
}
.title {
    color: #04922d;
    font-weight: 700;
    font-size: 38px;
}

.wrap-all-content-not-banner,
.padding-top-catalog-item{
  margin-top: 180px;
  @include respond-to('medium'){
    margin-top: 80px;
  }
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 30px;
		font-weight: 300;
		line-height: 1.2;
		color: #089ea8;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: #000;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}

.about-idit {
    position: relative;
    text-align: justify;
    margin-bottom: 35px;
	font-size: 16px;
	font-weight: normal;

    @include respond-to('larger') {
        padding: 10px;
    }
	.content-text{
        font-size: 16px;
        font-weight: normal;
	}
	& .content-text h1,
    & h1 {
        color: #04922d;
        font-weight: 700;
        font-size: 38px;
    }

    & p {
        font-size: 16px;
        font-weight: normal;
    }

    & h2,
	& h3,
	& h4, 
	& h5 {
        font-size: 20px;
        font-weight: 700;
        color: #04922d;
    }
	& strong{
        color: #04922d;
	}
}


.slide-image {
    padding-top: 100px;
    position: relative;
    color: white;
}
.content-texts-wrap{
	margin-right: -15px;
	margin-left: -15px;
}
.iframe-wrap{
    float: left;
    display: block;
    width: 100%;
	@include respond-to('small') {
		width: 100%;
	}
}
.iframe-wrap>iframe{
	width: 100%;
	height: 100%;
    border: 2px solid #04922d;
}
.contact-mobile{
display: none;
	@include respond-to('small') {
		display: block;
	}
}
.contact-desktop{
display: block;
@include respond-to('small') {
	display: none;
}
}