.wrapper-banners {
	position: relative;
	width: 100%;
	height: 697px;
	overflow: hidden;
	margin-top: 100px;
	@include respond-to('huge') {
		height: 697px;
	}
	@include respond-to('large') {
		height: 500px;
	}
	@include respond-to('extra-small') {
		height: 350px;
	}
	@include respond-to('medium') {
		margin-top: 70px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 697px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		// min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
}

.banner-item-content{
	position: absolute;
	top: 48%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) ;
	&__engtext{
		text-align: center;
		color:#FFFFFF;
		font-size:50px;
		font-weight: 700;
		padding-top: 30px;
		line-height: 50px;
		margin: 0;
		@include respond-to('medium') {
			font-size: 18px;
			padding-top: 10px;
			line-height: 150%;
		}
	}
	&__hebtext{
		text-align: center;
		color:#FFFFFF;
		font-size:50px;
		font-weight: 700;
		padding-bottom: 30px;
		line-height: 50px;
		margin: 0;
		@include respond-to('medium') {
			font-size: 18px;
			padding-bottom: 10px;
			line-height: 150%;
		}
	}

}
.sign1, .sign2{
	margin: auto;
}